<template>
  <main class="main news" v-if="articlesList">
    <page-slider
      :slides="articlesList[0].acf.banner_group.slides"
    ></page-slider>

    <b-container fluid="xl" class="my-5">
      <!-- <section-title v-text="pageData.acf.title" v-if="pageData.acf.title">news</section-title> -->
    </b-container>

    <b-container fluid="xl" class="px-0">
      <articles-list-item v-for="(item, index) in articlesList" :article="item">
        <b-container fluid="xl" class="my-3 px-10 px-md-5 px-xl-2">
          <divider class="bg-silver" />
        </b-container>
      </articles-list-item>
    </b-container>
  </main>
</template>

<script>
import axios from "@/axios";
import PageSlider from "../components/PageSlider";
import SectionTitle from "../components/SectionTitle.vue";
import FormCategoryMarket from "../components/FormCategoryMarket";
import ArticlesListItem from "../components/SearchListItem";
import Divider from "../components/Divider";

export default {
  components: {
    PageSlider,
    SectionTitle,
    FormCategoryMarket,
    ArticlesListItem,
    Divider,
  },
  data() {
    return {
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      currentPageSlug: "news",
      pageData: [],
      articlesList: [],
      spare_part_category: "",
      market_category: "",
      per_page: 5,
      page: 1,
    };
  },
  created() {
    this.getNews();
  },
  methods: {
    getNews() {
      axios
        // .get(this.backendUrl + "pages?slug=" + this.currentPageSlug)
        .get(
          `spare-parts?spare_part_category=${this.spare_part_category}&market_category=${this.market_category}&per_page=${this.per_page}&page=${this.page}`
        )
        .then((response) => {
          console.log(response);
          this.filters = response.data.filters;

          let object = response.data;
          delete object["market_category"];
          delete object["spare_part_category"];
          this.articlesList = object;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // chooseFirstFilter(item) {
    //   this.news_category = item;
    //   this.getNews();
    // },
    // chooseSecondFilter(item) {
    //   this.market_category = item;
    //   this.getNews();
    // },
    // seeMore() {
    //   this.page++;
    //   this.getNews();
    // },
  },
};
</script>

<style scoped>
.more {
  font-weight: 700;
}
</style>
