<template>
  <div>
    <b-row class="item">
      <!-- <b-col md="4" class="px-lg-4 px-md-4 col-12 order-md-2 mb-3 mb-md-0">
        <img
          v-if="article.acf.banner_group"
          :src="
            backendResourceDomain +
            article.acf.banner_group.slides[0].slide_image.sizes.square_large
          "
          alt=""
          class="img-fluid"
        />
      </b-col> -->
      <b-col md="9" class="">
        <div class="content d-flex flex-column px-3">
          <section-title class="text-left pb-4">{{
            article.title
          }}</section-title>
          <div class="meta d-flex pb-3">
            <span class="date">{{ article.post_date }}</span>
            <badges
              :categories="article.categories"
              :markets="article.markets"
              v-if="article.categories && article.markets"
            ></badges>
          </div>
          <p
            class="text-small excerpt"
            v-html="article.acf.short_description"
          ></p>
        </div>
      </b-col>
      <b-col md="3">
        <router-link
          v-if="article.post_type != 'page'"
          :to="'/' + article.post_type + '/' + article.id"
        >
          <b-button
            variant="primary"
            class="
              w-md-100
              p-3
              px-5
              mt-4
              mx-auto
              ml-md-5
              text-small text-uppercase
              d-block
            "
          >
            read more
          </b-button>
        </router-link>

        <router-link v-else :to="'/' + article.slug">
          <b-button
            variant="primary"
            class="
              w-md-100
              p-3
              px-5
              mt-4
              mx-auto
              ml-md-5
              text-small text-uppercase
              d-block
            "
          >
            open
          </b-button>
        </router-link>
      </b-col>
    </b-row>
    <slot></slot>
  </div>
</template>

<script>
import SectionTitle from "./SectionTitle";
import Badges from "./Badges";

export default {
  components: {
    SectionTitle,
    Badges,
  },
  data() {
    return {
      backendResourceDomain: "",
    };
  },
  props: {
    article: {
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/config";

.item {
  .meta {
    font-family: "NeoSansPro Medium";
    font-style: normal;
    .date {
      font-family: "NeoSansPro Light";
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
    }
  }
  .btn {
    max-width: 300px;
  }
}
</style>
